<template>
<ComponentsLayoutPage1>
  <v-row align="start" justify="start" class="py-5" :style="(windowMiddleSize) ? 'margin-bottom: 40px;' : 'margin-bottom: 40px;'">

    <v-col :cols="(windowMiddleSize) ? 12 : 8" :class="(windowMiddleSize) ? '' : 'py-1'" >
      <v-row align="start" justify="start" :class="(windowMiddleSize) ? '' : 'px-5'" no-gutters>
        <!-- お届け先情報 -->
        <v-col cols="12" class="py-3">
          <ComponentsViewFormsShoppingShipping
            :loading="isProcessLoading"
            @click="pageTransition('/shopping/shipping')"
          >
          </ComponentsViewFormsShoppingShipping>
        </v-col>
        <v-col cols="12" class="py-1" v-if="(!windowMiddleSize)">
          <v-divider></v-divider>
        </v-col>

        <!-- お支払い情報 -->
        <v-col cols="12" class="py-3">
          <ComponentsViewFormsShoppingPayment
            :loading="isProcessLoading"
            @click="pageTransition('/shopping/payment')"
          >
          </ComponentsViewFormsShoppingPayment>
        </v-col>
        <v-col cols="12" class="py-1" v-if="(!windowMiddleSize)">
          <v-divider></v-divider>
        </v-col>

        <!-- 配送情報 -->
        <v-col cols="12" class="py-3">
          <ComponentsViewFormsShoppingDelivery
            :loading="isProcessLoading"
            @click="() => false"
          >
          </ComponentsViewFormsShoppingDelivery>
        </v-col>
        <v-col cols="12" class="py-1" v-if="(!windowMiddleSize)">
          <v-divider></v-divider>
        </v-col>

        <!-- 販促物同梱希望 -->
        <v-col cols="12" class="py-3" v-if="CartSelectablePamphlet.length > 0">
          <ComponentsViewFormsShoppingPamphlet
            :loading="isProcessLoading"
          >
          </ComponentsViewFormsShoppingPamphlet>
        </v-col>
        <v-col cols="12" class="pt-1 pb-5" v-if="(!windowMiddleSize) && CartSelectablePamphlet.length > 0">
          <v-divider></v-divider>
        </v-col>

        <v-col cols=12 class="pt-3 pb-1">
          <ComponentsLayoutHeading1 text="商品情報" border-hidden></ComponentsLayoutHeading1>
        </v-col>

        <v-col cols=12 class="py-5">
          <v-card outlined>
            <v-card-text>
              <v-row align="center" justify="center" v-for="(item, index) in CartItems" :key="item.products_class_id">
                <v-col cols=12 class="pa-1">
                  <v-row align="start" justify="center" no-gutters v-if="isItemTypeProducts(item.dealings_cart_products)">
                    <v-col cols=3>
                      <ComponentsViewFormsSquareProductsImage
                        :img-src="(isUndefined(item.products_image)) ? '' : item.products_image[0].img_src"
                        :label="getBrandName(item.products.brand_id)"
                        :width-size="parseInt((imageWidthSize / 5))"
                      >
                      </ComponentsViewFormsSquareProductsImage>
                    </v-col>
                    <v-col :cols="(windowSpSize) ? 9 : 6">
                      <v-row align="center" justify="center" no-gutters>
                        <v-col cols=12 class='caption text-left py-1'>
                          <v-chip x-small label color="grey lighten-4" class="mr-2">商品</v-chip><span class="font-weight-bold" :style="{ color: buttonColor }" v-text="item.products.products_name"></span>
                        </v-col>
                        <v-col cols=12 class="caption text-left py-1">
                          <v-chip x-small label color="grey lighten-4" class="mr-2">規格</v-chip><span v-text="item.products_class.size"></span><span v-text="item.products_class.color"></span>
                        </v-col>
                        <v-col cols=12 class="caption warning--text text-left py-1">
                          <v-chip x-small label color="grey lighten-4" class="mr-2">卸価格 (税込)</v-chip>&yen;<span v-text="' ' + parseInt(item.dealings_cart_products.price).toLocaleString()"></span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col :cols="(windowSpSize) ? 12 : 3" class="py-3">
                      <v-text-field
                        v-model="item.dealings_cart_products.quantity"
                        filled
                        rounded
                        dense
                        hide-details
                        readonly
                        class="caption right-input pl-3"
                        :background-color="(item.dealings_cart_products.quantity == 0) ? '' : 'rgba(255, 152, 0, 0.2)'"
                        @click="viewCalculator(item.dealings_cart_products)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row align="start" justify="center" no-gutters v-else>
                    <!-- SP --->
                    <v-col cols=12 v-if="(windowSpSize)">
                      <v-row no-gutters>
                        <v-col cols=3>
                          <ComponentsViewFormsSquareProductsImage
                            :img-src="(isUndefined(item.original_products)) ? '' : item.original_products.img_src"
                            :label="'R2限定'"
                            :width-size="parseInt((imageWidthSize / 5))"
                          >
                          </ComponentsViewFormsSquareProductsImage>
                        </v-col>
                        <v-col cols=9>
                          <v-row align="center" justify="center" no-gutters>
                            <v-col cols=12 class='caption text-left py-1'>
                              <v-chip x-small label color="grey lighten-4" class="mr-2">商品</v-chip><span class="font-weight-bold" :style="{ color: buttonColor }" v-text="item.original_products.products_name"></span>
                            </v-col>
                            <v-col cols=12 class="caption warning--text text-left py-1">
                              <v-chip x-small label color="grey lighten-4" class="mr-2">卸価格 (税込)</v-chip>&yen;<span v-text="' ' + parseInt(item.original_products_price.price).toLocaleString()"></span>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols=12 class="py-3">
                          <v-text-field
                            v-model="item.dealings_cart_products.quantity"
                            filled
                            rounded
                            dense
                            hide-details
                            readonly
                            class="caption right-input pl-3"
                            :background-color="(item.dealings_cart_products.quantity == 0) ? '' : 'rgba(255, 152, 0, 0.2)'"
                            @click="viewCalculator(item.dealings_cart_products)"
                          ></v-text-field>
                        </v-col>
                        <v-col cols=12>
                          <v-row align="center" justify="center" no-gutters>
                            <v-col cols=12 class="caption warning--text text-left py-1">
                              <v-chip x-small label color="grey lighten-4" class="mr-2">セット内容</v-chip>
                            </v-col>
                            <v-col cols=12 class="caption text-left pl-3">
                              <v-row no-gutters v-for="setContents in item.dealings_favorites_original_set" :key="setContents.dealings_favorites_original_set.products_class_id">
                                <v-col cols=9>
                                  <v-row no-gutters>
                                    <v-col cols=12>
                                      <span v-text="getValue('web_name', getValue('products_class', setContents) || {})"></span>
                                      <span v-text="' ' + getValue('size', getValue('products_class', setContents) || {})" v-if="isUndefined(getValue('size', getValue('products_class', setContents) || {})) === false"></span>
                                      <span v-text="' ' + getValue('color', getValue('products_class', setContents) || {})" v-if="isUndefined(getValue('color', getValue('products_class', setContents) || {})) === false"></span>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols=3 class="text-center">
                                  &nbsp;&times;&nbsp;<span v-text="getValue('quantity', getValue('dealings_favorites_original_set', setContents) || {})"></span>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                    <!-- PC --->
                    <v-col cols=12 v-else>
                      <v-row no-gutters>
                        <v-col cols=3>
                          <ComponentsViewFormsSquareProductsImage
                            :img-src="(isUndefined(item.original_products)) ? '' : item.original_products.img_src"
                            :label="'R2限定'"
                            :width-size="parseInt((imageWidthSize / 5))"
                          >
                          </ComponentsViewFormsSquareProductsImage>
                        </v-col>
                        <v-col cols=9>
                          <v-row align="center" justify="center">
                            <v-col cols=8>
                              <v-row align="center" justify="center">
                                <v-col cols=12 class='caption text-left pt-3 pb-1'>
                                  <v-chip x-small label color="grey lighten-4" class="mr-2">商品</v-chip><span class="font-weight-bold" :style="{ color: buttonColor }" v-text="item.original_products.products_name"></span>
                                </v-col>
                                <v-col cols=12 class="caption warning--text text-left py-1">
                                  <v-chip x-small label color="grey lighten-4" class="mr-2">卸価格 (税込)</v-chip>&yen;<span v-text="' ' + parseInt(item.original_products_price.price).toLocaleString()"></span>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols=4 class="py-3">
                              <v-text-field
                                v-model="item.dealings_cart_products.quantity"
                                filled
                                rounded
                                dense
                                hide-details
                                readonly
                                class="caption right-input pl-3"
                                :background-color="(item.dealings_cart_products.quantity == 0) ? '' : 'rgba(255, 152, 0, 0.2)'"
                                @click="viewCalculator(item.dealings_cart_products)"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row align="center" justify="center">
                            <v-col cols=12 class="caption warning--text text-left py-1">
                              <v-chip x-small label color="grey lighten-4" class="mr-2">セット内容</v-chip>
                            </v-col>

                            <v-col cols=12 class="caption text-left pl-5">
                              <v-row class="pl-3" no-gutters v-for="setContents in item.dealings_favorites_original_set" :key="setContents.dealings_favorites_original_set.products_class_id">
                                <v-col cols=9>
                                  <v-row no-gutters>
                                    <v-col cols=12>
                                      <span v-text="getValue('web_name', getValue('products_class', setContents) || {})"></span>
                                      <span v-text="' ' + getValue('size', getValue('products_class', setContents) || {})" v-if="isUndefined(getValue('size', getValue('products_class', setContents) || {})) === false"></span>
                                      <span v-text="' ' + getValue('color', getValue('products_class', setContents) || {})" v-if="isUndefined(getValue('color', getValue('products_class', setContents) || {})) === false"></span>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols=3 class="text-center">
                                  &nbsp;&times;&nbsp;<span v-text="getValue('quantity', getValue('dealings_favorites_original_set', setContents) || {})"></span>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols=12 class="py-1" v-if="isUndefined(item.message) === false">
                  <v-alert dense border="left" type="error" class="caption">
                    <span v-text="item.message"></span>
                  </v-alert>
                </v-col>
                <v-col cols=12 class="py-1">
                  <v-divider v-if="(CartItems.length > (index + 1))"></v-divider>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <!--
        <v-col cols="12" class="py-5">
          <ComponentsItemsButton
            block
            large
            color="R2MainColorLight"
            :loading="isProcessLoading"
            text="注文を確定する"
            @click="() => false"
          >
          </ComponentsItemsButton>
        </v-col>
        --->
      </v-row>
    </v-col>

    <v-col :cols="(windowMiddleSize) ? 12 : 4" :class="(windowMiddleSize) ? '' : 'py-1'">
      <ComponentsLayoutSection1>
        <v-row align="start" justify="start">
          <v-col cols=6>
            <ComponentsLayoutHeading2 text="個数 （点数）" border-hidden></ComponentsLayoutHeading2>
          </v-col>
          <v-col cols=6 class="text-right font-weight-bold ec-font-size14">
            <span v-text="CartCalculation.quantity"></span>
          </v-col>
          <v-col cols=6>
            <ComponentsLayoutHeading2 text="小計 (税込)" border-hidden></ComponentsLayoutHeading2>
          </v-col>
          <v-col cols=6 class="text-right font-weight-bold ec-font-size14">
            &yen; <span v-text="CartCalculation.sub_total.toLocaleString()"></span>
          </v-col>
          <v-col cols=12 class="py-1">
            <v-divider></v-divider>
          </v-col>
          <v-col cols=6>
            <ComponentsLayoutHeading2 text="送料" border-hidden></ComponentsLayoutHeading2>
          </v-col>
          <v-col cols=6 class="text-right font-weight-bold ec-font-size14">
            &yen; <span v-text="CartCalculation.postage.toLocaleString()"></span>
          </v-col>
          <v-col cols=6>
            <ComponentsLayoutHeading2 text="手数料" border-hidden></ComponentsLayoutHeading2>
          </v-col>
          <v-col cols=6 class="text-right font-weight-bold ec-font-size14">
            &yen; <span v-text="commission.toLocaleString()"></span>
          </v-col>
          <v-col cols=6>
            <ComponentsLayoutHeading2 text="値引き" border-hidden></ComponentsLayoutHeading2>
          </v-col>
          <v-col cols=6 class="text-right font-weight-bold ec-font-size14 red--text">
            &yen; <span v-text="(CartCalculation.discount  * -1).toLocaleString()"></span>
          </v-col>
          <v-col cols=12 class="py-1">
            <v-divider></v-divider>
          </v-col>
          <v-col cols=6>
            <ComponentsLayoutHeading1 text="合計 (税込)" border-hidden></ComponentsLayoutHeading1>
          </v-col>
          <v-col cols=6 class="text-right font-weight-bold ec-font-size14">
            &yen; <span v-text="total.toLocaleString()"></span>
          </v-col>
          <v-col cols=12 v-if="postageFreeLinePrice > 0">
            <v-alert dense text type="info">
              <span>送料無料まで &yen; </span><span v-text="postageFreeLinePrice.toLocaleString()"></span>
            </v-alert>
          </v-col>
          <v-col cols=12 class="pb-5">
            <ComponentsItemsButton
              block
              large
              color="R2MainColorLight"
              :loading="isProcessLoading"
              :disabled="isProcessLoading"
              text="注文を確定する"
              @click="actionConfirm()"
            >
            </ComponentsItemsButton>
          </v-col>
        </v-row>
      </ComponentsLayoutSection1>
    </v-col>

    <v-dialog v-model="isCalculator" persistent scrollable width="320">
      <ComponentsItemsCalculator
        :show="isCalculator"
        :model="edit.quantity"
        @change="closeCalculator($event)"
        @close="isCalculator = false"
      >
      </ComponentsItemsCalculator>
    </v-dialog>
  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading2 from '@/components/layout/Heading2'
import ComponentsViewFormsShoppingShipping from '@/components/view-forms/ShoppingShipping'
import ComponentsViewFormsShoppingPayment from '@/components/view-forms/ShoppingPayment'
import ComponentsViewFormsShoppingDelivery from '@/components/view-forms/ShoppingDelivery'
import ComponentsViewFormsShoppingPamphlet from '@/components/view-forms/ShoppingPamphlet'
import ComponentsViewFormsSquareProductsImage from '@/components/view-forms/SquareProductsImage'
import ComponentsItemsCalculator from '@/components/items/Calculator'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ShoppingConfirmPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    isCalculator: false,
    edit: {
      quantity: 0
    }
  }),

  computed: {
    credits: {
      get () {
        return this.$store.getters['modStorage/PaymentsCredit']
      }
    },
    Cart: {
      get () {
        return this.$store.getters['modShopping/Cart']
      }
    },
    CartItems: {
      get () {
        return this.$store.getters['modShopping/CartItems']
      }
    },
    CartSelectablePamphlet: {
      get () {
        return this.$store.getters['modShopping/CartSelectablePamphlet']
      }
    },
    CartCalculation: {
      get () {
        return this.$store.getters['modShopping/CartCalculation']
      }
    },
    postageFreeLinePrice: {
      get () {
        return (this.CartCalculation.postage_free_line >= 0) ? this.CartCalculation.postage_free_line - this.CartCalculation.sub_total : -1
      }
    },
    commission: {
      get () {
        return this.CartCalculation.commission
      }
    },
    total: {
      get () {
        return this.CartCalculation.sub_total + this.CartCalculation.postage + this.commission - this.CartCalculation.discount
      }
    },
    windowWidthSize: {
      get () {
        return this.$store.getters['modPage/windowWidthSize']
      }
    },
    imageWidthSize: {
      get () {
        const widthSize = (this.windowWidthSize >= 1024) ? 1024 : this.windowWidthSize
        if (!this.$vuetify.breakpoint.smAndDown) {
          return parseInt(((widthSize / 12) * 8)) - 60
        }
        return parseInt(widthSize - 30)
      }
    }
  },

  mounted: function () {
    if (!this.isSignIn) {
      this.pageTransition('/')
    }
    this.goLoading()
  },

  methods: {

    goLoading: function () {
      // console.log(this.CartItems)
    },

    isItemTypeProducts: function (item) {
      return (item.order_item_type_status === process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_PRODUCTS)
    },

    goPrefectureName: function (prefID) {
      const index = this.prefectures.findIndex(row => row.code === prefID)
      return (index >= 0) ? this.prefectures[index].name : '不明'
    },

    viewCalculator: function (item) {
      this.edit = item
      this.isCalculator = true
    },

    closeCalculator: function (quantity) {
      (async () => {
        if (this.isItemTypeProducts(this.edit)) {
          await this.$store.dispatch('modShopping/SetShoppingCartProducts', [this.edit.products_class_id, quantity])
        } else {
          await this.$store.dispatch('modShopping/SetShoppingCartLimited', [this.edit.products_class_id, quantity])
        }
        // this.edit.quantity = quantity
        this.isCalculator = false
      })().catch((e) => {
        this.isCalculator = false
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },

    actionConfirm: function () {
      if (this.postageFreeLinePrice > 0) {
        this.$confirm({
          title: '確認',
          message: 'あと \\ ' + this.postageFreeLinePrice.toLocaleString() + ' で送料無料になりますが、注文を確定して本当によろしいですか？',
          button: { yes: '注文を確定する', no: '戻る' },
          callback: (action) => {
            if (this.isProcessLoading) return
            if (action) {
              this.actionCommit(true)
            }
          }
        })
      } else {
        this.actionCommit(true)
      }
    },

    actionCommit: function (ask) {
      (async () => {
        if (ask) {
          this.isProcessLoading = true
          try {
            await this.$store.dispatch('modShopping/Buy')
            await this.$store.dispatch('modShopping/LoadShoppingCart')
            this.pageTransition('/shopping/complete')
          } catch (e) {
            this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
          } finally {
            this.isProcessLoading = false
          }
        }
      })()
    }
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsLayoutSection1,
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading2,
    ComponentsViewFormsShoppingShipping,
    ComponentsViewFormsShoppingPayment,
    ComponentsViewFormsShoppingDelivery,
    ComponentsViewFormsShoppingPamphlet,
    ComponentsViewFormsSquareProductsImage,
    ComponentsItemsCalculator,
    ComponentsItemsButton
  }
}
</script>
